<template>
  <!--  Nested client only tags appear unnecessary (and broken) in new Nuxt version -->
  <!--  <client-only v-if="clientOnly">-->
  <!--    <component :is="currentComponent" :context="context"></component>&ndash;&gt;-->
  <!--  </client-only>-->
  <!--    <template v-else>-->
  <!--      <div>hello</div>-->
  <component :is="currentComponent" :context="context" :id="id"></component>
  <!--    </template>-->
</template>

<script lang="ts" setup>
  import type { IStateMachineRoutes, StateMachineContext } from '~/models/StateMachineModels'

  export interface StateMachineProps {
    components: { [key: string]: any }
    routes: IStateMachineRoutes
    clientOnly?: boolean | null
    initialData?: Array<{ key: string; value: any }> | null
    config?: string | null
    isStaging?: boolean | null
    id: string | null
  }

  const emit = defineEmits(['close'])

  const props = withDefaults(defineProps<StateMachineProps>(), {
    clientOnly: false,
    initialData: null,
    config: 'default',
    isStaging: false
  })

  const { components, routes, initialData, config, isStaging } = toRefs(props)

  const stack = ref([routes.value.initial])

  const current: ComputedRef<string> = computed(() => stack.value.slice(-1)[0])
  const currentComponent = computed(() => components.value[current.value])
  const currentRoute = computed(() => routes.value.components[current.value])

  const data = ref<Map<string, any>>(
    new Map(initialData?.value?.map((x) => [x.key, x.value]) ?? [])
  )

  const context = computed(
    () =>
      ({
        data: data.value,
        config: config?.value,
        isStaging: isStaging.value,
        current: current.value,
        canGoBack: stack.value.length > 1,
        back: () => stack.value.pop(),
        next: () => {
          if (currentRoute.value?.next) {
            stack.value.push(currentRoute.value.next)
          }
        },
        goTo: (component: string) => {
          stack.value.push(component)
        },
        clearStack: () => {
          stack.value = [current.value]
        }
      } as StateMachineContext)
  )
</script>

<style scoped></style>
